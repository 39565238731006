@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~bootstrap-icons/font/bootstrap-icons.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

i {
  display: flex;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

@media (max-width: 1400px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 1200px) {
  html {
    font-size: 80%;
  }
}

.required::after {
  content: '*';
  @apply text-red-500;
}

.mat-mdc-menu-content {
  padding: 0px !important;
  background: #ffffff !important;
  border-radius: 10px !important;
}

.mat-mdc-menu-panel {
  padding: 0px !important;
  background: #ffffff !important;
  border-radius: 10px !important;
}

.mat-mdc-menu-item {
  color: #ffffff !important;
  border-radius: 12px !important;
  transition: all 0.2s;
}

.mat-mdc-menu-item:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.mat-datepicker-toggle {
  svg {
    fill: #CCCCCC;
  }
}
