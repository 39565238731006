@import '../../../base.scss';

.custom-scrollbar {
  scrollbar-gutter: stable;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 3px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
